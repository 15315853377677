:root {
  --bg-gradient-1: #0d3859;
  --bg-gradient-2: #0c3150;
  --bg-gradient-3: #0b2b47;
  --bg-gradient-4: #09243e;
  --bg-gradient-5: #071e35;
  --bg-gradient-6: #031425;
  --bg-gradient-7: #00050a;

  --font-color: #f5f5f5;
  --link-color: #a0a0a0;
}

html {
  font-size: 12px;
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background-color: var(--bg-gradient-7);
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Sans Mono", monospace;
  color: var(--font-color);
  width: max-content;
  margin: 0 auto;
  position: relative;
}
a {
  font-family: "Noto Sans Mono", monospace;
  text-decoration: none;
  color: var(--link-color);
  width: max-content;
  font-size: clamp(1.5rem, 3vw, 2.5rem);
}

.full {
  height: 100vh;
}

.header {
  opacity: 0;
  animation: fadeindown 2s ease forwards;
  width: max-content;
  height: max-content;
  margin: 0 auto;
}
.header__heading {
  font-weight: 400;
  font-size: clamp(5rem, min(20vh, 14vw + 2rem), 12rem);
}
.header__subtitle {
  font-size: clamp(2rem, min(5vh, 4vw), 3rem);
  font-weight: 200;
}

.page-header {
  top: 0;
  margin-top: 5vh;
  width: 100%;
  height: max-content;
}

.page-header > h1 {
  margin-left: 5vw;
  position: relative;
}

.page-header > h1::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;

  bottom: 0;
  height: 2px;
  background-color: white;
}

.typingcover {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--bg-gradient-7);
  border-left: var(--font-color) 0.03em solid;
  animation: typewriter 3s infinite;
}

@keyframes fadeindown {
  to {
    opacity: 1;
  }
}

@keyframes typewriter {
  40%,
  60% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
#home {
  display: grid;
  position: relative;
  place-content: center;
}
#projects {
  background-image: linear-gradient(
    to bottom,
    var(--bg-gradient-1),
    var(--bg-gradient-2),
    var(--bg-gradient-3),
    var(--bg-gradient-4),
    var(--bg-gradient-5)
  );
}
#contact {
  background-image: linear-gradient(
    to bottom,
    var(--bg-gradient-5),
    var(--bg-gradient-6),
    var(--bg-gradient-7)
  );
}

main {
  gap: 5vw;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

main > a {
  transition: 0.5s ease;
  aspect-ratio: 1/1;
  max-width: 18vw;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.projects__main > a:hover {
  transform: translateY(-3vh);
}

.projects__main > a > div > img {
  vertical-align: middle;
  max-width: 100%;
}

.contact__main > a {
  font-size: clamp(4rem, 6vw, 8rem);
  text-align: center;
  background: transparent;
}

/* .navbar {
    position: sticky;
    top: 0;
    width: 100%;
    height: 20px;
    background-color: white;
} */

footer {
  padding-bottom: 1rem;
}

footer > h2 {
  margin-bottom: 5px;
}

.form {
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 50vw;
  font-family: "Noto Sans Mono", monospace;
}

label {
  color: whitesmoke;
}

input,
textarea,
button {
  font-weight: 200;
  outline: none;
  background: whitesmoke;
  border: none;
  font-size: 1.5rem;
}
input,
textarea {
  padding: 4px;
}

textarea {
  height: 10vh;
}

button {
  width: max-content;
  padding: 2px 0.2em;
  cursor: pointer;
  outline: none;
  color: var(--bg-gradient-1);
  align-self: flex-end;
}

@media (max-width: 768px) {
  main {
    padding: 1rem;
    /* flex-direction: column; */
  }

  main > a {
    max-width: 30vw;
  }

  .form {
    width: 90vw;
  }
}
